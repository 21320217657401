import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from "@angular/material/table";
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { InstallationService } from 'src/shared/services/installation.service';
import { Device, Division, Gateway, Installation, LampsType, PaginationInfo, InstallationSite } from './models/installation-models';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.less']
})

export class InstallationsComponent extends Unsubscriber implements OnInit {

  ngOnInit(): void {
    /** Verifica se uma empresa foi selecionada pelo usuário **/
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
    /** Lista os tipos de luminárias e divisões **/
    this.searchLampsType();
    this.searchDivision();
  }

  constructor(
    private installationService: InstallationService,
    private formBuilder: FormBuilder,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  /** Variáveis que contém as listas para serem carregadas no select de edição **/
  public equipmentListUpdate: Device[] = [];
  public siteListUpdate: InstallationSite[] = [];
  public gatewayListUpdate: Gateway[] = [];

  /** Variável que contém a lista de equipamentos que possuem instalações vinculadas (utilizada no filtro) **/
  public equipmentListFilter: Device[] = [];

  /** Variável que contém a lista de equipamentos (utilizada na criação e atualização de instalações) **/
  public equipmentList: Device[] = [];

  /** Variável que contém a lista de gateways (utilizada na criação e atualização de instalações) **/
  public gatewayList: Gateway[] = [];

  /** Variável que contém a lista de locais de instalações (utilizada na criação e atualização de instalações) **/
  public installationSiteList: InstallationSite[] = [];

  /** Variável que contém a lista de tipos de luminárias (utilizada apenas na criação de instalações) **/
  public lampsTypeList: LampsType[] = [];

  /** Variável que contém as divisões (subprefeituras) utilizado em algumas empresas na criação e  atualização de instalações **/
  public divisionList: Division[] = [];

  /** Variáveis de loading **/
  public filterLoading: boolean = false;
  public createLoading: boolean = false;
  public fileLoading: boolean = false;

  public loadingGateway: boolean = false;
  public installationSiteLoading: boolean = false;
  public equipmentsLoadingFilter: boolean = false;
  public equipmentsLoading = false;

  /** Variáveis utilizadas nos boxs de filtro e registro de instalações **/
  public registerInstallation: boolean = false;
  public filterInstallation: boolean = false;

  /** Variáveis para controlar o formulário de edição/visualização **/
  public viewController: boolean = false;
  public editorController: boolean = false;

  /** Variáveis para controlar os filtros de equipamento/gateway no menu de update**/
  public filteringDevice: boolean = false;
  public filteringGateway: boolean = false;

  /** Variáveis utilizadas na manipulação do checkbox de chave magnética/instalação virtual **/
  public virtualInstallationCheck: boolean = false;
  public hasMagneticKey: boolean = false;

  /** Variável que contém a empresa selecionada **/
  public company: any = null;

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  FILE_DATA: Installation[] = [];
  isReady = false;

  /** Variável responsável por popular a tabela com a lista de instalações **/
  public ELEMENT_DATA: Installation[] = [];

  /** Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<Installation>(this.ELEMENT_DATA)

  /** Variáveis utilizadas para manipulação do Paginator **/
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variáveis de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterInstallations(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterInstallations(this.paginationProperties.endCursor)
    }
  }

  /** Variável com as colunas da tabela **/
  public displayedColumns: string[] = [
    'reference',
    'installationSite',
    'equipmentType',
    'gateway',
    'lampsType',
    'division',
    'magneticKey',
    'status',
    'actions'
  ];

  /** Variáveis responsáveis por armazenar os dados do formulário de filtro de locais de instalações **/
  public filterReference: string | null = null;
  public filterAdress: string | null = null;
  public filterPostalCode: string | null = null;
  public filterPublicPlace: string | null = null;
  public filterNumber: number | null = null;
  public filterComplement: string | null = null;
  public filterDistrict: string | null = null;
  public filterCity: string | null = null;
  public filterState: string | null = null;
  public filterCountry: string | null = null;
  public filterLat: any = null;
  public filterLong: any = null;
  public filterPlaceholder: any = '';

  /** ViewChild responsáveis por receber os endereços da lib autocomplete **/
  @ViewChild('filter') filterField: ElementRef;

  /** Variável responsáveis por receber os endereços da lib autocomplete **/
  autocompleteFilter: google.maps.places.Autocomplete | undefined;

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

    /** Variaveis responsáveis por filtrar na API do google os endereços referentes aos viewchild **/
    this.autocompleteFilter = new google.maps.places.Autocomplete(this.filterField.nativeElement);

    /** Listener responsável por interceptar alterações no campo endereço completo(Formulário de filtro) **/
    this.autocompleteFilter.addListener('place_changed', () => {

      /** Variável responsável por receber o último endereço selecionado pelo usuário **/
      const place = this.autocompleteFilter?.getPlace();

      /**  Reseta todos os campos do formulário **/
      this.filterPostalCode, this.filterNumber, this.filterDistrict,
        this.filterCity, this.filterState, this.filterCountry,
        this.filterLat, this.filterLong = null;

      /** Atualiza todos os campos do formulário de acordo com o último endereço selecionado **/
      place?.address_components?.forEach((property) => {
        if (property?.types[0] === 'postal_code') {

          this.filterPostalCode = property?.short_name;
        }
        else if (property.types[0] === "street_number") {
          this.filterNumber = parseInt(property?.short_name);
        }
        else if (property.types[0] === "sublocality_level_1") {
          this.filterDistrict = property?.short_name;
        }
        else if (property.types[0] === "administrative_area_level_2") {
          this.filterCity = property?.short_name;
        }
        else if (property.types[0] === "administrative_area_level_1") {
          this.filterState = property?.short_name;
        }
        else if (property.types[0] === "country") {
          this.filterCountry = property?.short_name;
        }
        else if (property.types[0] === "route") {
          this.filterPublicPlace = property?.long_name
        }
      });

      this.filterLat = place?.geometry?.location?.lat();
      this.filterLong = place?.geometry?.location?.lng();
    });
  }

  /** Método e variáveis utilizadas no paginator **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /** controlador do box de criação **/
  public registerBoxOpen() {
    this.registerInstallation = !this.registerInstallation;
    this.filterInstallation = false;
  }

  /** Controlador do box de filtro. **/
  public filterBoxOpen() {
    this.filterInstallation = !this.filterInstallation;
    this.registerInstallation = false;
  }

  /** Método utilizado para a realizar a manipulação de fechar o SideBar de edição **/
  public closeEditionSideBar() {
    this.editorController = false;
    this.viewController = false;

    /** Limpa todas as listas **/
    this.installationSiteList = [];
    this.gatewayList = [];
    this.equipmentList = [];
  }

  /** Campos do formulário de filtro **/
  public filterForm: FormGroup = this.formBuilder.group({
    referenceFilter: [null],
    equipmentsTypeFilter: [null],
    selectedEquipmentFilter: [null],
  });

  /** Campos do formulário de criação **/
  public createForm: FormGroup = this.formBuilder.group({
    referenceCreate: [null, Validators.required],
    channel: [null],
    installationSite: [null, Validators.required],
    selectedInstallationSite: [null, Validators.required],
    equipmentsTypeCreate: [null],
    selectedEquipmentCreate: [null],
    gateway: [null],
    gatewaySelected: [null],
    division: [null],
    lampType: [null],
    applications: [null, Validators.required],
    hasMagneticKey: [null]
  });

  /** Campos do formulário de edição **/
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    referenceUpdate: [null],
    searchSiteUpdate: [null, Validators.required],
    siteSelectedUpdate: [null],
    searchEquipmentUpdate: [null],
    equipmentSelectedUpdate: [null],
    searchGatewayUpdate: [null],
    gatewaySelectedUpdate: [null],
    applications: [null, Validators.required],
    lampsType: [null],
    division: [null],
    isActive: [null],
    hasMagneticKey: [null],
    siteId: [null]
  })

  /** Método utilizado no botão de limpar filtro que reseta as informações no filtro **/
  public clearFilterForm(): any {
    this.filterForm.reset();
    this.filterAdress = "";
    this.filterCountry = "";
    this.filterState = "";
    this.filterCity = "";
    this.filterDistrict = "";
    this.filterPublicPlace = "";
    this.filterPostalCode = "";
  }

  /** Método que realiza o filtro de instalações **/
  public filterInstallations(
    cursor: string | null
  ): any {

    /** Ativa o loading **/
    this.filterLoading = true;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    /** Realiza o filtro de instalações **/
    this.subscriptions = this.installationService.filterInstallations(
      this.filterForm?.value?.referenceFilter,
      this.filterForm?.value?.selectedEquipmentFilter,
      this.filterCountry,
      this.filterState,
      this.filterCity,
      this.filterDistrict,
      this.filterPublicPlace,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data.installation.count,
          response.data.installation.total,
          response.data.installation.pageInfo.hasNextPage,
          response.data.installation.pageInfo.hasPreviousPage,
          response.data.installation.pageInfo.startCursor,
          response.data.installation.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = response.data.installation.total;
        this.isReady = false;

        response.data.installation.edges.forEach((node: any) => {
          /** Itera cada elemento de instalação,
           *  divisão, local de instalação, device, 
           * gateway e tipos de luminária na lista **/
          this.ELEMENT_DATA.push(

            new Installation(
              node.node?.id,
              node.node?.reference,
              node.node?.isActive,
              node.node?.hasMagneticKey,
              node.node?.applications[0],
              new Division(
                node.node?.division?.id,
                node.node?.division?.reference ?? ''
              ),
              new InstallationSite(
                node.node?.site?.id,
                node.node?.site?.reference,
                node.node?.site?.number,
                node.node?.site?.district,
                node.node?.site?.state,
                node.node?.site?.city,
                node.node?.site?.country,
                node.node?.site?.geoposition?.latitude,
                node.node?.site?.geoposition?.longitude,
                node.node?.site?.street
              ),
              new Device(
                node.node?.device?.id,
                node.node?.device?.serialNumber,
                node.node?.device?.equipmentType?.reference ?? '',
                node.node?.device?.equipmentType?.major ?? '',
                node.node?.device?.equipmentType?.minor ?? '',
                node.node?.device?.equipmentType?.revision ?? ''
              ),
              new Gateway(
                node.node?.gateway?.id,
                node.node?.gateway?.reference,
              ),
              new LampsType(
                node.node?.lampType?.id,
                node.node?.lampType?.model
              )
            )
          )
        });

        /** Atualiza os valores da tabela **/
        this.setDataSourceAttributes();

        /** Caso não retorne nada na lista **/
        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });

          /** Desativa o loading **/
          this.filterLoading = false;
        }

        /** Desativa o loading **/
        this.filterLoading = false;

      }), /** Caso de algum erro **/
      error: (error: any) => {
        /** Exibe um alerta **/
        this.errorLibService.errorAlert(error);
      }
    })
  }

  /** Método que realiza a criação de instalações **/
  public createInstallation(): any {
    /** Caso todos os campos do formulário sejam válidos **/
    if (this.createForm.valid) {
      /** Ativa o loading **/
      this.createLoading = true;
      /** Realiza a criação da instalação **/
      this.subscriptions = this.installationService.createInstallation(
        this.createForm?.value?.referenceCreate,
        this.createForm?.value?.selectedInstallationSite,
        this.createForm?.value?.selectedEquipmentCreate,
        this.createForm?.value?.gatewaySelected,
        this.createForm?.value?.lampType,
        this.hasMagneticKey,
        this.createForm?.value?.applications,
        this.createForm?.value?.division
      ).subscribe({
        next: (() => {
          /** Campo de referencia do formulário de filtro recebe o valor da referencia que foi cadastrada **/
          this.filterForm.patchValue({
            referenceFilter: this.createForm?.value.referenceCreate
          });

          /** Realiza a filtro novamente **/
          this.filterInstallations(null);

          /** Limpa o valor do input reference **/
          this.filterForm.patchValue({
            referenceFilter: null
          });

          /** Exibe alerta de sucesso **/
          Swal.fire({
            title: 'Instalação criada',
            text: 'Instalação criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          /** Desativa o loading **/
          this.createLoading = false;
          /** Limpa todas as listas assim que criado a instalação **/
          this.equipmentList = [];
          this.installationSiteList = [];
          this.gatewayList = [];
          /** Reseta o formulário de criação após a criação **/
          this.createForm.reset();

          this.createForm.get('installationSite')?.addValidators(Validators.required);
          this.createForm.get('installationSite')?.updateValueAndValidity();
        }),

        /* Caso de algum erro **/
        error: (error: any) => {
          /** Exibe no console o error **/
          console.log("createInstallation", error)
          /** Exibe um alerta **/
          this.errorLibService.errorAlert(error);

          this.createLoading = false;
        }
      })
    }
    else {
      /** Caso algum campo não tenha sido preenchido, emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Seta todos os campos com os elementos preenchidos no formulário de edição **/
  public openSidebarUpdate(element: any, isViewing: boolean): any {

    /** Controlador de visualização atribuido como o parametro de visualização  **/
    this.viewController = isViewing;

    /** Caso esteja na opção para visualizar
     * e o status da instalação esteja como inativo
     *  desativa a edição do formulário **/
    if (isViewing || element.isActive === false) {
      this.updateForm.disable();
    }
    /** se estiver na opção de edição habilita o formulário para edição normalmente **/
    else {
      this.updateForm.enable();
    }

    /** preenchendo com os elementos de equipamentos no select que pode ser editado **/
    this.equipmentListUpdate.push(new Device(
      element?.device?.id,
      element?.device?.serialNumber,
      element?.device?.reference,
      element?.device?.major,
      element?.device?.minor,
      element?.device?.revision
    )),

      /** preenchendo com os elementos de locais de instalação no select que pode ser editado **/
      this.siteListUpdate.push(new InstallationSite(
        element?.site?.id,
        element?.site?.reference,
        element?.site?.number,
        element?.site?.district,
        element?.site?.state,
        element?.site?.city,
        element?.site?.country,
        element?.site?.lat,
        element?.site?.long,
        element?.site?.street
      )),

      /** preenchendo com os elementos no select que pode ser editado **/
      this.gatewayListUpdate.push(new Gateway(
        element?.gateway?.id,
        element?.gateway?.reference
      )),

      /** Atribuindo os campos de edição a cada elemento da instalação **/
      this.updateForm.get('id')?.setValue(element?.id),
      this.updateForm.get('referenceUpdate')?.setValue(element?.reference),
      this.updateForm.get('hasMagneticKey')?.setValue(element?.hasMagneticKey),
      this.updateForm.get('searchSiteUpdate')?.setValue(element?.site?.reference),
      this.updateForm.get('siteSelectedUpdate')?.setValue(element?.site?.id),
      this.updateForm.get('siteId')?.setValue(element?.site?.id),
      this.updateForm.get('searchEquipmentUpdate')?.setValue(element?.device?.serialNumber),
      this.updateForm.get('equipmentSelectedUpdate')?.setValue(element?.device?.id),
      this.updateForm.get('searchGatewayUpdate')?.setValue(element?.gateway?.reference),
      this.updateForm.get('gatewaySelectedUpdate')?.setValue(element?.gateway?.id),
      this.updateForm.get('applications')?.setValue(this.convertApplicationsName(element?.applications)),
      this.updateForm.get('lampsType')?.setValue(element?.lampsType?.id),
      this.updateForm.get('division')?.setValue(element?.division?.id),
      this.updateForm.get('isActive')?.setValue(element?.isActive),

      /** Abre o sidebar de edição **/
      this.editorController = true;
  }

  /** Método que realiza a atualização/edição das instalações **/
  public updateInstallation(): any {
    // Armazena os valores selecionados pelo usuário no menu de update
    let device = this.updateForm?.value?.equipmentSelectedUpdate;
    let gateway = this.updateForm?.value?.gatewaySelectedUpdate;

    /** Caso o usuário deixe o campo de equipamento/gateway em branco **/
    if (this.updateForm.value?.searchEquipmentUpdate == '' && this.filteringDevice == false) {
      /** Dados de equipamento são enviados nulos. **/
      device = null
    }

    if (this.updateForm.value?.searchGatewayUpdate == '' && this.filteringGateway == false) {
      /** Dados de gateways são enviados nulos. **/
      gateway = null;
    }

    /** Se todos os campos de edição estiverem devidamente preeenchidos **/
    if (this.updateForm.valid) {
      /** Realiza a atualização dos dados de instalações **/
      this.subscriptions = this.installationService.updateInstallation(
        this.updateForm?.value?.id,
        this.updateForm?.value?.referenceUpdate,
        this.updateForm?.value?.siteSelectedUpdate,
        device,
        gateway,
        this.updateForm?.value?.applications,
        this.updateForm?.value?.isActive,
        this.updateForm?.value?.hasMagneticKey,
        this.updateForm?.value?.lampsType,
        this.updateForm?.value?.division
      ).subscribe({
        /** Captura a resposta de sucesso **/
        next: ((response: any) => {

          /** Exibe alerta de sucesso **/
          Swal.fire({
            title: 'Instalação atualizada',
            text: 'Instalação atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          /** Realiza o filtro atualizando a tabela **/
          this.filterInstallations(null);

          /** Cria um novo elemento com as informações que foram editadas **/
          this.ELEMENT_DATA = [
            new Installation(
              response.data.installationUpdate.installation?.id,
              response.data.installationUpdate.installation?.reference,
              response.data.installationUpdate.installation?.isActive,
              response.data.installationUpdate.installation?.hasMagneticKey,
              response.data.installationUpdate.installation?.applications[0],
              new Division(
                response.data.installationUpdate.installation?.division?.id,
                response.data.installationUpdate.installation?.division?.reference,
              ),
              new InstallationSite(
                response.data.installationUpdate.installation.site?.id,
                response.data.installationUpdate.installation.site?.reference,
                response.data.installationUpdate.installation.site?.number,
                response.data.installationUpdate.installation.site?.district,
                response.data.installationUpdate.installation.site?.state,
                response.data.installationUpdate.installation.site?.city,
                response.data.installationUpdate.installation.site?.country,
                response.data.installationUpdate.installation.site?.geoposition?.latitude,
                response.data.installationUpdate.installation.site?.geoposition?.longitude,
                response.data.installationUpdate.installation.site?.street,
              ),
              new Device(
                response.data.installationUpdate.installation.device?.id,
                response.data.installationUpdate.installation.device?.serialNumber,
                response.data.installationUpdate.installation.device?.equipmentType?.reference ?? '',
                response.data.installationUpdate.installation.device?.equipmentType?.major ?? '',
                response.data.installationUpdate.installation.device?.equipmentType?.minor ?? '',
                response.data.installationUpdate.installation.device?.equipmentType?.revision ?? ''
              ),
              new Gateway(
                response.data.installationUpdate.installation.gateway?.id,
                response.data.installationUpdate.installation.gateway?.reference,
              ),
              new LampsType(
                response.data.installationUpdate.installation.lampType?.id,
                response.data.installationUpdate.installation.lampType?.model
              )
            )
          ];

          /**Atualiza os valores da tabela **/
          this.setDataSourceAttributes();

          /** Fecha o sidebar de edição **/
          this.editorController = false;

          /** Limpa todas as listas **/
          this.installationSiteList = [];
          this.gatewayList = [];
          this.equipmentList = [];

          // Reinicia as variáveis para controle do filtro no update
          this.filteringDevice = false;
          this.filteringGateway = false;

        }),
        /** Caso de algum erro **/
        error: (error: any) => {
          /** Exibe no console o error **/
          console.log("updateInstallation", error)
          /** Exibe um alerta **/
          this.errorLibService.errorAlert(error);
        }
      })
    }
    /** Caso algum campo não esteja preenchido corretamente exibe um alerta ao usuário **/
    else {
      Swal.fire({
        text: 'Confirme todos os dados antes de atualizar!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método que realiza a exclusão de instalação 
   * Atualmente essa funcionalidade está caindo no erro 500, (será necessário corrigir no back-end)
   * A instalação está sendo removida normalmente 
  **/
  public deleteInstallation(): any {
    /** Exibe alerta para confirmação **/
    Swal.fire({
      title: 'Tem certeza que deseja deletar a instalação?',
      text: 'Esta ação poderá afetar em outras funcionalidades do sistema que dependem desta instalação',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar'
      /** Captura o resultado **/
    }).then((result) => {
      /** Se a opção for confirmada, realiza a requisição de exclusão **/
      if (result.isConfirmed) {
        this.subscriptions = this.installationService.deleteInstallation(this.updateForm.value?.id).subscribe({
          next: () => {
            /** Realiza o filtro atualizando a tabela **/
            this.filterInstallations(null);
            /** Fecha o sidebar de edição **/
            this.editorController = false;

            /** Exibe mensagem de sucesso **/
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: 'Instalação deletada com sucesso'
            })
          },
          /** Captura o erro e exibe no console **/
          error: (error: any) => {
            console.log('mutation delete installation', error)

            this.editorController = false
            /** Exibe mensagem de erro **/
            Swal.fire({
              icon: 'error',
              title: 'Algo deu errado',
              text: 'Não foi possível deletar a instalação'
            })
          }
        })
      }
      /** Caso não seja possível deletar exibe uma mensagem **/
      else if (result.isDenied) {
        Swal.fire({
          title: 'Exclusão não realizada',
          text: 'Tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
      }
    })
  }

  /** Método que valida o status de instalação inativada ou ativada **/
  public updateStatusInstallation(status: boolean) {
    /** Variáveis criadas para definir o texto a ser exibido quando a instalação for ativada/inativada **/
    let title: string;
    let text: string;

    /** Caso o status esteja como inativado **/
    if (status) {
      title = 'Tem certeza que deseja ativar a instalação?'
      text = 'Está instalação será ativada'
    }

    /** Caso o status esteja como ativado **/
    else {
      title = 'Tem certeza que deseja inativar a instalação?'
      text = 'Está instalação será inativada'
    }

    /** Exibe alerta para confirmação **/
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
      /** Pega o resultado **/
    }).then((result) => {
      /** Caso seja confirmado **/
      if (result.isConfirmed) {
        /** Chama a requisição para inativar/ativar a instalação **/
        this.subscriptions = this.installationService.statusInstallations(
          this.updateForm.value?.id,
          this.updateForm.value?.referenceUpdate,
          status,
          this.updateForm.value?.siteId,
          this.updateForm.value?.applications,
          this.updateForm.value?.gatewaySelectedUpdate

        ).subscribe({
          next: (() => {

            /** Fecha o sidebar de edição **/
            this.editorController = false;

            /** Realiza o filtro atualizando a tabela **/
            this.filterInstallations(null);
          }),

          /** Caso de algum erro **/
          error: (error: any) => {
            /** Exibe no console o error **/
            console.log("updateStatusInstallation", error)
            /** Exibe um alerta **/
            this.errorLibService.errorAlert(error);
          }
        })

        /** Exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Operação realizada com sucesso'
        })
      }

      /** Caso não for possivel ativar/inativar a instalação **/
      else if (result.isDenied) {
        /** Exibe informação ao usuário **/
        Swal.fire({
          title: 'Operação não realizada',
          text: 'Tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
      }
    })
  }

  /** Validação para verificar se é uma instalação virtual  **/
  public isVirtualInstallation(checked: boolean): any {
    /** Se o usuário selecionar o checkbox de instalação virtual o valor será true **/
    if (checked) {
      this.virtualInstallationCheck = true;
    }
    else {
      this.virtualInstallationCheck = false;
    }
  }

  /** Validação para verificar se possui chave magnética **/
  public ifHasMagneticKey(checked: boolean): any {
    /** Se o usuário selecionar o checkbox de chave magnética o valor de chave magnética passa a ser true  **/
    if (checked) {
      this.hasMagneticKey = true;
    }
    else {
      this.hasMagneticKey = false;
    }
  }

  /** Para realizar o filtro dos equipamentos (utilizado apenas no filtro) **/
  public searchEquipmentsFilter(serialNumber: string | null): any {
    /** Ativa o loading **/
    this.equipmentsLoadingFilter = true;
    this.subscriptions = this.installationService
      .getEquipments(serialNumber).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.equipment.edges.forEach((node: any) => {
            this.equipmentListFilter.push(new Device(
              node.node?.id,
              node.node?.serialNumber,
              node.node?.equipmentType?.reference,
              node.node?.equipmentType?.major,
              node.node?.equipmentType?.minor,
              node.node?.equipmentType?.revision,
            )
            );
          })
          /** Desativa o loading**/
          this.equipmentsLoadingFilter = false;

          if (this.equipmentListFilter.length === 0) {
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }
        }),
        error: ((error: any) => {
          console.log("searchEquipments")
          this.errorLibService.errorAlert(error);

          /** Desativa o loading**/
          this.equipmentsLoadingFilter = false;
        })
      })
  }

  /** Realiza o filtro dos equipamentos (utilizado na criação e atualização de instalações) **/
  public searchEquipments(serialNumber: string | null): any {
    /** Ativa o loading na tela **/
    this.equipmentsLoading = true;

    this.filteringDevice = true;

    /** Utilizado no update para desativar o validator assim que o usuário clica para filtrar o equipamento **/
    // this.updateForm.get('searchEquipmentUpdate')?.clearValidators();
    // this.updateForm.get('searchEquipmentUpdate')?.updateValueAndValidity();
    this.subscriptions = this.installationService
      .getEquipments(serialNumber).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.equipment.edges.forEach((node: any) => {
            this.equipmentList.push(new Device(
              node.node?.id,
              node.node?.serialNumber,
              node.node?.equipmentType?.reference,
              node.node?.equipmentType?.major,
              node.node?.equipmentType?.minor,
              node.node?.equipmentType?.revision,
            )
            );
          })

          /** Desativa o loading **/
          this.equipmentsLoading = false;
          /** Caso a lista esteja vazia **/
          if (this.equipmentList.length === 0) {
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }

        }),
        error: ((error: any) => {
          console.log("searchEquipments")
          this.errorLibService.errorAlert(error);

          /** Desativa o loading **/
          this.equipmentsLoading = false;
        })
      })
  }

  /** Realiza o filtro de locais de instalações (utilizado na criação e atualização de instalações) **/
  public searchInstallationSite(reference: string | null): any {
    /** Ativa o loading **/
    this.installationSiteLoading = true;
    /** Utilizado no update para desativar o validator assim que o usuário clica para filtrar o local de instalação **/
    this.updateForm.get('searchSiteUpdate')?.clearValidators();
    this.updateForm.get('searchSiteUpdate')?.updateValueAndValidity();

    this.createForm.get('installationSite')?.clearValidators();
    this.createForm.get('installationSite')?.updateValueAndValidity();

    this.subscriptions = this.installationService.getInstallationsSite(reference).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.installationSite.edges.forEach((node: any) => {
          this.installationSiteList.push(new InstallationSite(
            node.node?.id,
            node.node?.reference,
            node.node?.number,
            node.node?.district,
            node.node?.state,
            node.node?.city,
            node.node?.country,
            node.node?.geoposition?.latitude,
            node.node?.geoposition?.longitude,
            node.node?.street
          ))
        })

        /** Desativa o loading **/
        this.installationSiteLoading = false;

        /** Caso a lista esteja vazia **/
        if (this.installationSiteList.length === 0) {
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      error: ((error: any) => {
        console.log("searchInstallationSite")
        this.errorLibService.errorAlert(error);

        /** Desativa o loading **/
        this.installationSiteLoading = false;
      })
    })
  }

  /** Realiza o filtro de gateways (utilizado na criação e atualização de instalações) **/
  public searchGateway(reference: string | null): any {
    /** Ativa o loading **/
    this.loadingGateway = true;

    this.filteringGateway = true;

    this.subscriptions = this.installationService.getGateways(reference).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.gatewayInstallation.edges.forEach((node: any) => {
          this.gatewayList.push(new Gateway(
            node.node?.id,
            node.node?.reference
          ))
        })
        /** Desativa o loading **/
        this.loadingGateway = false;

        /** Caso a lista esteja vazia **/
        if (this.gatewayList.length === 0) {
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("searchGateways")

        /** Desativa o loading **/
        this.loadingGateway = false;
      })
    })
  }

  /** Realiza o filtro de tipos de luminárias (utilizado apenas na criação de instalações )**/
  public searchLampsType(): any {
    this.subscriptions = this.installationService.getLampsType().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.lampType.edges.forEach((node: any) => {
          this.lampsTypeList.push(new LampsType(
            node.node?.id,
            node.node?.model
          ))
        })
        /** Caso a lista esteja vazia **/
        if (this.lampsTypeList.length === 0) {
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("searchLampsType")
      })
    })
  }

  /** Realiza o filtro da divisão, utilizado na criação e atualização de divisão (nem todas as empresas utilizam esse filtro) **/
  public searchDivision(): any {
    this.subscriptions = this.installationService.getDivision().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.division.edges.forEach((node: any) => {
          this.divisionList.push(new Division(
            node.node?.id,
            node.node?.reference
          ))
        })
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("searchDivision")
      })
    })
  }

  /** Mapeamento para fazer a validação das variáveis de aplicação que estão enumeradas no back (usado apenas no update) **/
  public convertApplicationsName(name: string): string | null {
    /** Caso o nome na api esteja "light" **/
    if (name == 'light') {
      /** Retorna como  **/
      return 'PUBLIC_LIGHTING'
    }

    if (name == 'electricity') {
      return 'ELECTRICITY_CONSUMPTION'
    }

    if (name == 'water') {
      return 'WATER_CONSUMPTION'
    }

    if (name == 'gas') {
      return 'GAS_CONSUMPTION'
    }
    return name
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.installationService.filterInstallations(
        this.filterForm?.value?.referenceFilter,
        this.filterForm?.value?.selectedEquipmentFilter,
        this.filterCountry,
        this.filterState,
        this.filterCity,
        this.filterDistrict,
        this.filterPublicPlace,
        cursor,
        10
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.installation.edges.forEach((node: any) => {
            /** Itera cada elemento de instalação,
             *  divisão, local de instalação, device, 
             * gateway e tipos de luminária na lista **/
            this.FILE_DATA.push(

              new Installation(
                node.node?.id,
                node.node?.reference,
                node.node?.isActive,
                node.node?.hasMagneticKey,
                node.node?.applications,
                new Division(
                  node.node?.division?.id,
                  node.node?.division?.reference ?? ''
                ),
                new InstallationSite(
                  node.node?.site?.id,
                  node.node?.site?.reference,
                  node.node?.site?.number,
                  node.node?.site?.district,
                  node.node?.site?.state,
                  node.node?.site?.city,
                  node.node?.site?.country,
                  node.node?.site?.geoposition?.latitude,
                  node.node?.site?.geoposition?.longitude,
                  node.node?.site?.street
                ),
                new Device(
                  node.node?.device?.id,
                  node.node?.device?.serialNumber,
                  node.node?.device?.equipmentType?.reference ?? '',
                  node.node?.device?.equipmentType?.major ?? '',
                  node.node?.device?.equipmentType?.minor ?? '',
                  node.node?.device?.equipmentType?.revision ?? ''
                ),
                new Gateway(
                  node.node?.gateway?.id,
                  node.node?.gateway?.reference,
                ),
                new LampsType(
                  node.node?.lampType?.id,
                  node.node?.lampType?.model
                )
              )
            )
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.installation?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.installation?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }

  /** Método para realizar o download do arquivo CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.reference,
        'Local de instalação ': `${element.site.district} ${element.site.city} ${element.site.street} - ${element.site.number}`,
        'Tipo de Equipamento': `${element.device.reference}${element.device.major}${element.device.minor}${element.device.revision}`,
        'Concentrador': element.gateway.reference,
        'Tipo de Lâmpada': element.lampType.model,
        'Divisão': this.translateDivision(element.division.reference),
        'Chave Magnética': this.translateMagneticKey(element.hasMagneticKey),
        'Status': this.translateStatus(element.isActive)
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Installations.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método para realizar o download do arquivo PDF **/
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Referência",
      "Local de instalação",
      "Tipo de Equipamento",
      "Concentrador",
      "Tipo de Lâmpada",
      "Divisão",
      "Chave Magnética",
      "Status"
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.reference,
        `${element.site.district} - ${element.site.city}, ${element.site.street}, ${element.site.number}`,
        `${element.device.reference}${element.device.major}${element.device.minor}${element.device.revision}`,
        element.gateway.reference,
        element.lampType.model,
        this.translateDivision(element.division.reference),
        this.translateMagneticKey(element.hasMagneticKey),
        this.translateStatus(element.isActive)

      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-installations.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Função criada para tratar os dados de chave magnética para o relatório de CSV  e PDF**/
  public translateMagneticKey(magneticKey: any): string {
    switch (magneticKey) {
      /** Caso possua chave magnética **/
      case true:
        /** Retorna no arquivo como "Possui" **/
        return "Possui";

      /** Caso não possua chave magnética **/
      case false:
        return "Não possui";
    }
    /** Retorna a chave magnética para utilizar nos arquivos que geram os arquivos de PDF e CSV **/
    return magneticKey;
  }

  /** Função criada para tratar os dados de status para os relatórios CSV e PDF **/
  public translateStatus(status: any): string {
    switch (status) {
      /** Caso o status seja ativado **/
      case true:
        /** Retorna o status da instalação como ativa */
        return "Ativa";

      /** Caso o status seja inativado **/
      case false:
        /**Retorna o status da instalação como inativa */
        return "Inativa";
    }
    /** Retorna o status para utilizar nos arquivos que geram os arquivos de PDF e CSV **/
    return status;
  }

  /** Método que trata dos dados de divisão caso estejam vazios **/
  public translateDivision(division: any) {
    /** Caso não tenha conteúdo no campo de divisão **/
    if (division === '') {
      /** retorna no arquivo como "Não vinculado" **/
      division = "Não vinculado"
    }
    /** retorna a divisão para utilizar nos métodos que geram os arquivos de CSV e PDF **/
    return division
  }
}
