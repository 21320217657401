export class User {
  constructor(
    public id: string,
    public username: string,
    public name: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public lastLogin: string,
    public superuser: string,
    public companies: Company[],
    public permissions: Permission[],
    public permissionsGroups: PermissionGroup[],
    public isActive: string,
  ) { }
}
export class Company {
  constructor(
    public id: string,
    public reference: string,
    public checked: boolean = false,
    public disabled?: boolean
  ) { }
}
/*
label - rotulo, nome da empresa value é o id

checked começa como false por padrão
*/

export class Permission {
  constructor(
    public id: string,
    public reference: string,
    public codeName: string,
    public checked: boolean = false,
    public disabled?: boolean
  ) { }
}

export class PermissionGroup {
  constructor(
    public id: string,
    public reference: string,
    public userSet: string,
    public checked: boolean = false,
    public disabled?: boolean
  ) { }
}

export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}
