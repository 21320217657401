import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { catchError, from, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import { InstallationService } from 'src/shared/services/installation.service';
import { Installation } from '../../../assets-imports-csv/imports-csv-installations-model';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-imports-csv-disassociate-equipment-in-installation',
  templateUrl: './imports-csv-disassociate-equipment-in-installation.component.html',
  styleUrls: ['./imports-csv-disassociate-equipment-in-installation.component.less']
})

export class ImportsCsvDisassociateEquipmentInInstallationComponent {
  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    private installationService: InstallationService,
    public dialog: MatDialog
  ) { }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  private data: string;

  /** Array que armazena os dados do arquivo CSV **/

  private dataToImport: Array<any> = [];

  /** Utilizado para manipular a msg exibida após os logs estarem disponíveis para download (sem o update) **/
  public logReadyMessage: boolean = false;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean = false;

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Loading de update **/
  public loadingUpdate: boolean = false;

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa as linhas dos logs **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza o mapeamento do retorno das propriedade de aplicações nas instalações **/
  private applicationMapping(application: string): any {

    let response: string = '';

    switch (application) {
      case 'light':
        response = 'PUBLIC_LIGHTING';
        break;

      case 'electricity':
        response = 'ELECTRICITY_CONSUMPTION';
        break;

      case 'water':
        response = 'WATER_CONSUMPTION';
        break;

      case 'gas':
        response = 'GAS_CONSUMPTION';
        break;

      default:
        response = ''
        break;
    }
    return response;
  }

  /** Método que realiza a importação dos dados vindos do arquivo CSV **/
  public async importDataFromCSV() {

    /** Armazena os dados do CSV **/
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    /** Constante que armazena o objeto que contém as propriedades de referência e numero de série do arquivo **/
    const dataMappings = this.dataToImport.map(row => ({
      reference: row.reference
    }));

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(

      /** Processa as instalações e equipamentos limitando as solicitações em 5 **/
      mergeMap(({ reference }) => this.processInstallationAndDisassociateEquipment(reference), 5),

      /** Caso ocorra algum erro **/
      catchError(err => {

        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;

        /** Desativa o loading **/
        this.loadingUpdate = false;

        /** Botão é ativado **/
        this.disableImportButton = false;

        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {

            /** Ativa o botão de exportar logs **/
            this.isExported = true;

            /** Desativa o loading **/
            this.loadingUpdate = false;

            /** Botão de importação é desativado **/
            this.disableImportButton = true;

            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;

            /** Reseta o contador das linhas **/
            this.actualLine = 0;

            /** Limpa o input dos arquivos que contém o arquivo anexado 
           já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Método que realiza o update de instalações removendo o equipamento das instalações e retorna um Observable **/
  private processInstallationAndDisassociateEquipment(reference: string): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;

    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;

    /** Desabilita o botão de importação **/
    this.disableImportButton = true;

    /** Variável que armazena as informações das instalações  **/
    let installation: Installation;

    /** Realiza o filtro de instalações passando a referência existente no arquivo como parâmetro **/
    return this.installationService.getInstallationByReference(reference).valueChanges.pipe(
      take(1),

      /** Mapeamento dos resultados obtidos do filtro de instalação **/
      mergeMap((res: any) => {

        /** Filtrando instalações pela referência exata obtida no arquivo **/
        const installations: Installation[] = res.data.installation.edges.map((edge: any) => edge.node)
          .filter((install: Installation) => install.reference === reference)

        /** Caso a instalação não seja encontrada **/
        if (installations.length === 0) {

          /** Adiciona na lista de logs **/
          this.log.push(`Referência ${reference} não encontrada nas instalações válidas!`);

          /** Encerrando esse processo **/
          return of(null);
        }

        /** Atribuindo apenas uma instalação com a referência correta **/
        installation = installations[0];

        /** Realiza a atualização da instalação enviando os campos já existentes na instalação e removendo apenas o equipamento **/
        return this.importsCSVFileService.updateInInstallation(
          installation?.id,
          installation?.reference,
          installation?.site?.id,
          null, // enviando o equipamento nulo (desassociando da instalação)
          installation?.isActive,
          installation?.hasMagneticKey,
          this.applicationMapping(installation?.applications[0]),
          installation?.lampType?.id,
          installation?.gateway?.id,
          installation?.division?.id
        ).pipe(
          tap(() => {
            /** Adiciona mensagem de sucesso na lista de logs **/
            this.log.push(`O equipamento foi desassociado da instalação ${reference} com sucesso!`);
          }),
          /** Caso ocorra algum erro na atualização **/
          catchError(err => {
            /** Exibe o erro no console **/
            console.error(`Falha ao desassociar o equipamento da instalação: ${reference}`, err);

            /** Adiciona o erro na lista de logs **/
            this.log.push(`Falha ao desassociar o equipamento da instalação: ${reference} (${err})`);

            /** Continua o processo mesmo com erro **/
            return of(null);
          })
        );
      })
    );
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Disassociate_Equipment.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  public downloadModelCsvFile(): void {
    const headers = 'reference'; //Define o cabeçalho do arquivo csv
    const fileName = 'model-import-disassociate-equipment.csv'; //Nome do arquivo
    const csvContent = [headers]; // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob(csvContent, { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
