import { gql } from "apollo-angular";

const CREATE_CIRCUIT_BOX = gql`
mutation circuitBoxCreate(
  $reference: String!,
  $sgIpc: ID!,
  $sgGateway: ID!,
  $sgConsumption: ID!
) {
    circuitBoxCreate(input:{
      reference: $reference
      sgIpc: $sgIpc
      sgGateway: $sgGateway
      sgConsumption: $sgConsumption
    }){
      circuitBox{
        id
        reference
        sgIpc{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
          }
        }
        sgGateway{
          id
          reference
          site{
            id
            reference
            geoposition{
              latitude
              longitude
            }
            company{
              id
              name
            }
            country
            state
            city
            district
            street
            number
            details
            locationCode
          }
          device{
            id
            serialNumber
            macAddress
            company{
              id
              name
            }
            equipmentType{
              id
              reference
              major
              minor
              revision
            }
          }
        }
        sgConsumption{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
          }
        }
      }
    }
  }
`

const GET_CIRCUIT_BOX = gql`
  query all(
	$company: ID!
	$reference: String
	$first: Int
	$cursor: String
	$before: String
	$last: Int
  $referenceContains: String
  $sgIpcMacAddresses: [String]
) {
	circuitBox(
		company: $company
		reference: $reference
		first: $first
	  after: $cursor
		last: $last
		before: $before
		referenceContains: $referenceContains
    sgIpcMacAddresses: $sgIpcMacAddresses
	) {
		edges {
			node {
				id
				reference
				sgIpc {
					id
					serialNumber
					macAddress
          equipmentType{
            id
            reference
          }
				}
				sgGateway {
					id
					reference
				}
				sgConsumption {
					id
					serialNumber
				}
			}
			cursor
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}
`;

const UPDATE_CIRCUIT_BOX = gql`
mutation circuitBoxUpdate(
  $id: ID!,
  $reference: String!,
  $sgIpc: ID!,
  $sgGateway: ID!,
  $sgConsumption: ID!
) {
  circuitBoxUpdate(input:{
      id: $id
      reference: $reference
      sgIpc: $sgIpc
      sgGateway: $sgGateway
      sgConsumption: $sgConsumption
    }){
      circuitBox{
        id
        reference
        sgIpc{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
          }
        }
        sgGateway{
          id
          reference
          site{
            id
            reference
            geoposition{
              latitude
              longitude
            }
            company{
              id
              name
            }
            country
            state
            city
            district
            street
            number
            details
            locationCode
          }
          device{
            id
            serialNumber
            macAddress
            company{
              id
              name
            }
            equipmentType{
              id
              reference
              major
              minor
              revision
            }
          }
        }
        sgConsumption{
          id
          serialNumber
          macAddress
          company{
            id
            name
          }
          equipmentType{
            id
            reference
            major
            minor
            revision
          }
        }
      }
    }
  }
`

export {
  // Queries
  GET_CIRCUIT_BOX,
  // Mutations
  CREATE_CIRCUIT_BOX,
  UPDATE_CIRCUIT_BOX,
}
