export class ReportChartData {
    constructor(
        public id: string | null,
        public dateTime: string | null,
        public voltage: string | null,
        public current: string | null,
        public activePower: string | null,
        public apparentPower: string | null,
        public power: string | null,
        public consumption: string | null,
        public powerFactor: string | null,
        public time: string | null
    ) { }
}
