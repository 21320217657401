import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GENERATE_ALERT_REPORT,
  COMMAND_LIST_REPORT_MUTATION,
  EVENTS_REPORT_MUTATION,
  FIRST_ON_FIRST_OFF_REPORT_MUTATION,
  GET_ALL_REPORTS,
  GET_REPORT_DOWNLOAD_URL,
  INDIVIDUAL_CONSUMPTION_REPORT,
  INDIVIDUAL_STATUS_MUTATION,
  INSTALLATION_LIST_REPORT,
  LAST_DATA_MUTATION,
  LAST_TRANSMISSION_MUTATION,
  METEOROLOGICAL_REPORT_MUTATION,
  NO_TRANSMISSION_REPORT_MUTATION,
  REGISTER_INSTALLATIONS_REPORT,
  REPORT_DELETE,
  GENERATE_GATEWAY_CONSUMPTION,
  POSSIBLE_STATUS_REPORT_MUTATION,
  CIRCUIT_BOX_CONSUMPTION_MUTATION,
  SYSTEM_ALERT_REPORT
} from 'src/app/graphql/reports.graphql';
import { Gateway } from '../components/report/models/installation.model';
import {
  CommandReportModel,
  EventsModel,
  IndividualConsumptionModel,
  IndividualStatusReportModel,
  InstallationListModel,
  LastTransmissionModel,
  MetereologicalReportModel,
  NoTransmissionReportModel,
  StatusModel,
  InstallationRecordModel,
  LatestDataModel,
  SystemAlertsModel
} from '../components/report/models/report.model';
import { ReportFilter } from '../components/report/models/reportFilter.model';
import { FILTER_INSTALLATIONS } from 'src/app/graphql/installations.graphql';
import { GET_CIRCUIT_BOX } from 'src/app/graphql/circuit-box.graphql';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private apollo: Apollo) { }

  private company = localStorage.getItem('lastCompanySelected');

  public getAllReports(cursor: string | null, reportFilter: ReportFilter | null, pageSize: number | null): any {

    const variables = {
      company: this.company,
      ...(reportFilter?.reference ? { reference: reportFilter.reference } : {}),
      ...(reportFilter?.reportType ? { reportType: reportFilter.reportType } : {}),
      ...(reportFilter?.requestedBy ? { requestedBy: reportFilter.requestedBy } : {}),
      first: pageSize,
      ...(cursor ? { after: cursor } : {}),
    };

    return this.apollo.watchQuery({
      query: GET_ALL_REPORTS,
      fetchPolicy: 'network-only',
      variables
    })
  }

  public handlePreviousPage(cursor: string | null, reportFilter: ReportFilter | null, pageSize: number | null) {
    const variables = {
      company: this.company,
      ...(reportFilter?.reference ? { reference: reportFilter.reference } : {}),
      ...(reportFilter?.reportType ? { reportType: reportFilter.reportType } : {}),
      ...(reportFilter?.requestedBy ? { requestedBy: reportFilter.requestedBy } : {}),
      last: pageSize,
      ...(cursor ? { before: cursor } : {}),
    };

    return this.apollo.watchQuery({
      query: GET_ALL_REPORTS,
      fetchPolicy: 'network-only',
      variables
    })
  }

  public dateFormatter(date: any): string {
    let year = date.slice(0, 4);
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    let time = date.slice(11, 16);
    return (day + '/' + month + '/' + year + ' ' + time);
  }

  public getReportDownloadLink(reportId: any, companyId: any) {
    return this.apollo.watchQuery<any>({
      query: GET_REPORT_DOWNLOAD_URL,
      variables: {
        report: reportId,
        company: companyId
      }
    })
  }

  public deleteReport(reportId: any, companyId: any) {
    return this.apollo.mutate({
      mutation: REPORT_DELETE,
      variables: {
        report: [reportId],
        company: companyId
      }
    })
  }

  /** Relatório de Últimas Transmissões **/
  public generateLastTransmissionReport(lastTransmission: LastTransmissionModel) {
    return this.apollo.mutate({
      mutation: LAST_TRANSMISSION_MUTATION,
      variables: {
        company: this.company,
        reference: lastTransmission.reference,
        timezone: lastTransmission.timezone
      }
    })
  }

  /** Relatório de Cadastro de Instalações **/
  public generateInstallationRecordReport(installationRecord: InstallationRecordModel) {
    return this.apollo.mutate({
      mutation: REGISTER_INSTALLATIONS_REPORT,
      variables: {
        company: this.company,
        reference: installationRecord.reference,
        timezone: installationRecord.timezone
      }
    })
  }

  /** Relatório de Últimos Dados **/
  public generateLatestDataReport(latestData: LatestDataModel) {
    return this.apollo.mutate({
      mutation: LAST_DATA_MUTATION,
      variables: {
        company: this.company,
        reference: latestData.reference,
        timezone: latestData.timezone
      }
    })
  }

  /** Relatório de Alertas **/
  public generateAlertReport(statusReport: StatusModel) {
    return this.apollo.mutate({
      mutation: GENERATE_ALERT_REPORT,
      variables: {
        company: this.company,
        reference: statusReport.reference,
        installations: statusReport.installationIdList,
        startDatetime: statusReport.startDatetime,
        endDatetime: statusReport.endDatetime,
        timezone: statusReport.timezone
      }
    })
  }

  public generateSystemStatusReport(systemAlertReport: SystemAlertsModel) {
    return this.apollo.mutate({
      mutation: SYSTEM_ALERT_REPORT,
      variables: {
        company: this.company,
        reference: systemAlertReport.reference,
        startDatetime: systemAlertReport.startDatetime,
        endDatetime: systemAlertReport.endDatetime,
        timezone: systemAlertReport.timezone
      }
    })
  }

  /** Relatório de Consumo **/
  public generateIndividualConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_CONSUMPTION_REPORT,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        kind: individualConsumptionReport.kind,
        macAddress: individualConsumptionReport.macAddress,
        timezone: individualConsumptionReport.timezone
      }
    })
  }

  /** Relatório de Consumo p/ caixa de circuito **/
  public generateCircuitConsumptionReport(individualConsumptionReport: IndividualConsumptionModel) {
    return this.apollo.mutate({
      mutation: CIRCUIT_BOX_CONSUMPTION_MUTATION,
      variables: {
        company: this.company,
        reference: individualConsumptionReport.reference,
        startDatetime: individualConsumptionReport.startDateTime,
        endDatetime: individualConsumptionReport.endDateTime,
        macAddress: individualConsumptionReport.macAddress,
        timezone: individualConsumptionReport.timezone
      }
    })
  }

  /** Relatório de Status **/
  public generateIndividualStatusReport(individualStatusReport: IndividualStatusReportModel) {
    return this.apollo.mutate({
      mutation: INDIVIDUAL_STATUS_MUTATION,
      variables: {
        company: this.company,
        reference: individualStatusReport.reference,
        startDatetime: individualStatusReport.startDatetime,
        endDatetime: individualStatusReport.endDatetime,
        macAddress: individualStatusReport.macAddress,
        timezone: individualStatusReport.timezone
      }
    })
  }

  /** Relatório de Eventos **/
  public generateEventsReport(events: EventsModel) {
    return this.apollo.mutate({
      mutation: EVENTS_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: events.reference,
        startDatetime: events.startDatetime,
        endDatetime: events.endDatetime,
        macAddress: events.macAddress,
        timezone: events.timezone
      }
    })
  }

  /** Relatório Meteorológico **/
  public generateMeteorologicalReport(metereologicalReport: MetereologicalReportModel) {
    return this.apollo.mutate({
      mutation: METEOROLOGICAL_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: metereologicalReport.reference,
        startDate: metereologicalReport.startDatetime,
        endDate: metereologicalReport.endDatetime,
        macAddress: metereologicalReport.macAddress,
        timezone: metereologicalReport.timezone
      }
    })
  }

  /** Relatório Meteorológico **/
  public generateCommandsListReport(commandReport: CommandReportModel) {
    return this.apollo.mutate({
      mutation: COMMAND_LIST_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: commandReport.reference,
        startDate: commandReport.startDatetime,
        endDate: commandReport.endDatetime,
        timezone: commandReport.timezone
      }
    })
  }

  /** Relatório de Lista de Instalações **/
  public generateInstallationListReport(installationList: InstallationListModel) {
    return this.apollo.mutate({
      mutation: INSTALLATION_LIST_REPORT,
      variables: {
        company: this.company,
        reference: installationList.reference
      }
    })
  }

  /** Relatório de Sem Transmissão **/
  public generateNoTransmissionReport(noTransmissionReport: NoTransmissionReportModel) {
    return this.apollo.mutate({
      mutation: NO_TRANSMISSION_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: noTransmissionReport.reference,
        hours: noTransmissionReport.hours,
        timezone: noTransmissionReport.timezone
      }
    })
  }

  /** Relatório Primeiro ligado e primeiro desligado (Utilizado apenas para IluminaSP) **/
  public generateFirstOnFirstOff(reference: string | null, targetDate: string, timezone: string | null) {
    return this.apollo.mutate({
      mutation: FIRST_ON_FIRST_OFF_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: reference,
        targetDate: targetDate,
        timezone: timezone
      }
    })
  }

  /** Relatório de Possível Status (Utilizado apenas para IluminaSP) **/
  public generatePossibleStatus(reference: string | null, targetDate: string, timezone: string | null) {
    return this.apollo.mutate({
      mutation: POSSIBLE_STATUS_REPORT_MUTATION,
      variables: {
        company: this.company,
        reference: reference,
        targetDate: targetDate,
        timezone: timezone
      }
    })
  }

  /** Relatório de Consumo por Rede **/
  public generateGatewayConsumption(reference: string, gateway: Gateway, startDatetime: String, endDatetime: String, timezone: string | null) {
    return this.apollo.mutate({
      mutation: GENERATE_GATEWAY_CONSUMPTION,
      variables: {
        company: this.company,
        reference: reference,
        gateway: gateway.id,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        timezone: timezone
      }
    })
  }

  /** Algumas páginas usam essa consulta aqui. **/
  public getInstallationByReference(referenceContains: string | null, cursor: string | null = null,
    pageSize: number | null = null) {
    return this.apollo.query({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains: referenceContains,
        pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  // Filtra instalação por empresa
  public getCircuitBoxesByReference(company: string | null, referenceContains: string | null) {
    return this.apollo.query({
      query: GET_CIRCUIT_BOX,
      fetchPolicy: 'network-only',
      variables: {
        company: company,
        referenceContains: referenceContains,
        first: 15
      }
    })
  }
}
