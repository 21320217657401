import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Observable, catchError, from, mergeMap, of, take, tap } from 'rxjs';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import { Division, Equipment, Gateway, LampsType, Site } from '../../../assets-imports-csv/imports-csv-installations-model';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-imports-csv-create-installations',
  templateUrl: './imports-csv-create-installations.component.html',
  styleUrls: ['./imports-csv-create-installations.component.less']
})

export class ImportsCsvCreateInstallationsComponent {
  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    public dialog: MatDialog
  ) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Armazena a empresa selecionada pelo usuário **/
  public company: string | null;

  /** Armazena o conteúdo do arquivo **/
  private data: string;

  /** Array que armazena os dados do arquivo CSV **/
  private dataToImport: Array<any> = [];

  public logReadyMessage: boolean;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean;

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Loading de update **/
  public loadingUpdate: boolean;

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa a lista de logs **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza o mapeamento do retorno das propriedades de aplicações nas instalações **/
  private applicationMapping(application: string | null): any {

    let response: string = '';

    switch (application) {
      case 'light':
        response = 'PUBLIC_LIGHTING';
        break;

      case 'electricity':
        response = 'ELECTRICITY_CONSUMPTION';
        break;

      case 'water':
        response = 'WATER_CONSUMPTION';
        break;

      case 'gas':
        response = 'GAS_CONSUMPTION';
        break;

      default:
        response = ''
        break;
    }
    return response;
  }

  // Método que converte os campos de chave magnética /
  private parseMagneticKey(magneticKey: string | null): any {

    // Conversão dos campos de chave magnética /
    switch (magneticKey) {
      // Caso exista chave magnética /
      case '1':
        // Retorna como true p/ chave magnética /
        return true;
      // Caso não exista chave magnética /
      case '0':
        // Retorna como false p/ chave magnética /
        return false;
      case null:
        return false

      // Retorna a string de chave magnética convertida /
      default:
        return JSON.parse(magneticKey)
    }
  }

  /** Método que realiza a leitura e importação dos arquivos CSV **/
  public async importDataFromCSV() {

    /** Armazena os dados do CSV **/
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    /** Constante que armazena o objeto que contém as propriedades para criação dos equipamentos **/
    const dataMappings = this.dataToImport.map(row => ({
      reference: row.reference,
      equipment: row.equipment,
      gateway: row.gateway,
      lampType: row.lampType,
      magneticKey: row.magneticKey,
      applications: row.applications,
      division: row.division,
      site: row.site,
      latitude: row.latitude,
      longitude: row.longitude,
      country: row.country,
      state: row.state,
      city: row.city,
      district: row.district,
      street: row.street,
      number: row.number,
      details: row.details,
      locationCode: row.locationCode
    }));

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(
      /** Processa todos os campos da instalação, limitando as solicitações em 5 **/
      mergeMap(({
        reference,
        equipment,
        gateway,
        lampType,
        magneticKey,
        applications,
        division,
        site,
        latitude,
        longitude,
        country,
        state,
        city,
        district,
        street,
        number,
        details,
        locationCode
      }) =>
        this.processInstallationsAndCreate(
          reference,
          equipment,
          gateway,
          lampType,
          magneticKey,
          applications,
          division,
          site,
          latitude,
          longitude,
          country,
          state,
          city,
          district,
          street,
          number,
          details,
          locationCode
        ), 5),
      /** Caso ocorra algum erro **/
      catchError(err => {
        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;
        /** Desativa o loading **/
        this.loadingUpdate = false;
        /** Botão é ativado **/
        this.disableImportButton = false;
        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {
            /** Ativa o botão de exportar logs **/
            this.isExported = true;
            /** Desativa o loading **/
            this.loadingUpdate = false;
            /** Desativa o botão de importação **/
            this.disableImportButton = true;
            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;
            /** Reseta o contador das linhas **/
            this.actualLine = 0;
            /** Limpa o input dos arquivos que contém o arquivo anexado 
          já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Processa os dados da instalação e realiza a criação de instalações **/
  private processInstallationsAndCreate(
    installationReference: string,
    equipmentSerialNumber: string | null,
    gatewayReference: string | null,
    lampsType: string | null,
    magneticKey: string | null,
    applications: string | null,
    divisionReference: string | null,
    siteReference: string | null,
    latitude: any,
    longitude: any,
    country: string | null,
    state: string | null,
    city: string | null,
    district: string | null,
    street: string | null,
    number: string | null,
    details: string | null,
    locationCode: string | null
  ): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;
    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;
    /** Desabilita o botão de importação **/
    this.disableImportButton = true;

    /** Variáveis que armazenam as informações relacionadas a 
     * Local de instalação,
     * Tipo de luminárias,
     * Divisão (subprefeitura),
     * Gateways e  
     * Equipamentos
     *  **/
    let site: Site;
    let lampType: LampsType;
    let division: Division;
    let gateway: Gateway;
    let equipment: Equipment;

    return this.importsCSVFileService.getLampsTypeByModel(lampsType)
      .valueChanges.pipe(
        take(1),
        mergeMap((res: any) => {
          /** Constante que armazena o nó de response do tipo de luminárias **/
          const lamp: LampsType[] = res.data.lampType.edges.map((edge: any) => edge.node)
            /** Busca na API pelo modelo de luminária exata contida no arquivo **/
            .filter((lampType: LampsType) => lampType.model === lampsType);

          /** Caso o tipo de luminária existente no arquivo não exista na API **/
          if (lamp.length === 0) {
            /** Tipo de luminária é enviado como nulo **/
            lampsType = null;
          }

          /** Caso o tipo de luminária informado no arquivo exista, é armazenado e enviado apenas o que contém o modelo exato **/
          lampType = lamp[0];

          /** Realiza a requisição que filtra a divisão por referência, passando a referência da divisão contida no arquivo **/
          return this.importsCSVFileService.getDivisionsByReference(divisionReference)
            .valueChanges.pipe(
              take(1),
              mergeMap((res: any) => {
                /** Constante que armazena o nó de response da divisão **/
                const divisions: Division[] = res.data.division.edges.map((edge: any) => edge.node)
                  /** Busca na API pela referência exata da divisão contida no arquivo **/
                  .filter((division: Division) => division.reference === divisionReference);

                /** Caso a referência da divisão existente no arquivo não exista na API **/
                if (divisions.length === 0) {
                  /** Referência da divisão é enviada como nulo **/
                  divisionReference = null;
                }

                /** Caso a divisão informada no arquivo exista, é armazenado e enviado apenas o que contém a referência exata **/
                division = divisions[0];

                /** Realiza a requisição que filtra os gateways por referência, passando a referência do gateway contida no arquivo **/
                return this.importsCSVFileService.getGatewaysByReference(gatewayReference)
                  .valueChanges
                  .pipe(
                    take(1),
                    mergeMap((res: any) => {
                      /** Constante que armazena o nó de response dos gateways **/
                      const gateways: Gateway[] = res.data.gatewayInstallation.edges.map((edge: any) => edge.node)
                        /** Busca na API pela referência exata do gateway contida no arquivo **/
                        .filter((gateway: Gateway) => gateway.reference === gatewayReference);

                      /** Caso a referência do gateway existente no arquivo não exista na API **/
                      if (gateways.length === 0) {
                        /** Referência do gateway é enviada como nulo **/
                        gatewayReference = null;
                      }

                      /** Caso o gateway informado no arquivo exista, é armazenado e enviado apenas o que contém a referência exata **/
                      gateway = gateways[0];

                      /** Realiza a requisição que filtra os equipamentos por número de série, passando o numero de série do equipamento contido no arquivo **/
                      return this.importsCSVFileService.getEquipmentsBySerialNumber(equipmentSerialNumber)
                        .valueChanges
                        .pipe(
                          take(1),
                          mergeMap((res: any) => {
                            /** Constante que armazena o nó de response dos equipamentos **/
                            const equipments: Equipment[] = res.data.equipment.edges.map((edges: any) => edges.node)
                              /** Busca na API pelo número de série exato do equipamento contido no arquivo **/
                              .filter((equipment: Equipment) => equipment.serialNumber === equipmentSerialNumber)

                            /** Caso o numero de série do equipamento existente no arquivo não exista na API **/
                            if (equipments.length === 0) {
                              /** Número de série do equipamento é enviada como nulo **/
                              equipmentSerialNumber = null;
                            }

                            /** Caso o número de série do equipamento informado no arquivo exista, 
                             * é armazenado e enviado apenas o que contém o número de série exato **/
                            equipment = equipments[0];

                            return this.importsCSVFileService.getInstallationSiteByReference(siteReference)
                              .valueChanges
                              .pipe(
                                take(1),
                                mergeMap((res: any) => {
                                  const installationSite: Site[] = res.data.installationSite.edges.map((edge: any) => edge.node)
                                    /** Busca na API pelo local de instalação exato contido no arquivo **/
                                    .filter((site: Site) => site.reference === siteReference);

                                  /** Caso o local de instalação existente no arquivo não exista na API **/
                                  if (installationSite.length === 0) {

                                    /** Realiza a criação do local de instalação **/
                                    return this.importsCSVFileService.createInstallationSite(
                                      /** Envia todos os campos do local de instalação presentes no arquivo **/
                                      latitude,
                                      longitude,
                                      siteReference,
                                      country,
                                      state,
                                      city,
                                      district,
                                      street,
                                      number,
                                      details,
                                      locationCode
                                    ).pipe(
                                      take(1),
                                      mergeMap((res: any) => {
                                        /** Variável que armazena o id do local de instalação para ser enviado na criação de instalação **/
                                        let siteId = res.data.installationSiteCreate.installationSite.id
                                        /** Realiza a requisição de criação de instalação **/
                                        return this.createInstallation(
                                          /** Envia todos os campos de instalação presentes no arquivo **/
                                          installationReference,
                                          equipment?.id,
                                          gateway?.id,
                                          lampType?.id,
                                          magneticKey,
                                          applications,
                                          division?.id,
                                          siteId
                                        )
                                      }),
                                      catchError((error: any) => {
                                        /** Caso ocorra algum erro ao criar o local de instalação, exibe erro no console do navegador **/
                                        this.log.push(`Erro ao criar a instalação: ${siteReference} (${error})`);
                                        return of(null);
                                      })
                                    )
                                  } else {

                                    /** Pega o local de instalação exato encontrado **/
                                    site = installationSite[0];

                                    return this.createInstallation(
                                      /** Envia todos os campos de instalação presentes no arquivo **/
                                      installationReference,
                                      equipment?.id,
                                      gateway?.id,
                                      lampType?.id,
                                      magneticKey,
                                      applications,
                                      division?.id,
                                      site?.id
                                    );
                                  }
                                }),
                                catchError((error: any) => {
                                  this.log.push(`Erro  ao filtrar o local de instalação referente a instalação: ${installationReference} (${error})`)
                                  /** Caso ocorra algum erro ao filtrar o local de instalação, exibe erro no console do navegador **/
                                  console.log(`Erro ao filtrar o local de instalação ${siteReference}, ${error}`);
                                  return of(null);
                                })
                              );
                          }),
                          catchError((error: any) => {
                            /** Caso ocorra algum erro ao filtrar o equipamento, exibe erro no console do navegador **/
                            console.log(`Erro ao filtrar o equipamento ${equipmentSerialNumber}, ${error}`);
                            this.log.push(`Erro ao filtrar o equipamento ${equipmentSerialNumber} referente a instalação: ${installationReference} (${error})`)
                            return of(null);
                          })
                        );
                    }),
                    catchError((error: any) => {
                      console.log(`Erro ao filtrar o gateway ${gatewayReference}, ${error}`);
                      this.log.push(`Erro ao filtrar o gateway ${gatewayReference} referente a instalação ${installationReference}(${error})`);
                      return of(null);
                    })
                  );
              }),
              catchError((error: any) => {
                this.log.push(`Erro ao filtrar a divisão ${divisionReference} referente a instalação: ${installationReference} (${error})`)
                /** Caso ocorra algum erro ao filtrar a divisão, exibe erro no console do navegador **/
                console.log(`Erro ao filtrar a divisão ${divisionReference}, ${error}`);
                return of(null);
              })
            );
        }),
        catchError((error: any) => {
          /** Caso ocorra algum erro ao filtrar o tipo de luminária, exibe erro no console do navegador **/
          this.log.push(`Erro ao filtrar o tipo de luminária referente a instalação: ${installationReference} (${error})`)
          console.log(`Erro ao filtrar o tipo de luminária ${lampsType}, ${error}`);
          return of(null);
        })
      );
  }

  /** Método que realiza o tratamento dos dados e criação de instalações **/
  private createInstallation(
    installationReference: string,
    equipment: string | null,
    gateway: string | null,
    lampTypeId: string | null,
    magneticKey: string | null,
    applications: string | null,
    division: string | null,
    site: string | null,
  ): Observable<any> {

    /** Realiza a requisição de criação de instalações **/
    return this.importsCSVFileService.createInstallation(
      installationReference,
      equipment,
      gateway,
      lampTypeId,
      this.parseMagneticKey(magneticKey),
      this.applicationMapping(applications),
      division,
      site
    ).pipe(
      tap(() => {
        /** Adiciona na lista de logs todas as instalações que foram criadas **/
        this.log.push(`Instalação: ${installationReference} cadastrada com sucesso!`);
      }),
      /** Caso ocorra algum erro ao cadastrar as instalações **/
      catchError(error => {
        /** Adiciona informação na lista de logs **/
        this.log.push(`Falha ao criar a instalação: ${installationReference} (${error})`);
        /** Continua o processo mesmo com erro **/
        return of(null);
      })
    );
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Create_Installations.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método utilizado para gerar o conteúdo do CSV a partir dos cabeçalhos **/
  private generateCsvContent(headers: string[]): string {
    return headers.join(';') + '\n'; // Agrupa os cabeçalhos separando por ponto e vírgula e adiciona uma nova linha no final
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  public downloadCsvFile(): void {
    const headers = [
      'reference',
      'equipment',
      'gateway',
      'lampType',
      'magneticKey',
      'site',
      'applications',
      'division',
      'latitude',
      'longitude',
      'country',
      'state',
      'city',
      'district',
      'street',
      'number',
      'details',
      'locationCode'
    ]; //Define o cabeçalho do arquivo csv

    const fileName = 'model-import-create-installation.csv'; //Nome do arquivo
    const csvContent = this.generateCsvContent(headers); // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
