import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Observable, catchError, from, mergeMap, of, take, tap } from 'rxjs';
import { EquipmentsService } from 'src/shared/services/equipments.service';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import { InstallationService } from 'src/shared/services/installation.service';
import Swal from 'sweetalert2';
import { Device, Installation } from '../../assets-imports-csv/imports-csv-installations-model';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-imports-csv-installations',
  templateUrl: './imports-csv-equipment-in-installations.component.html',
  styleUrls: ['./imports-csv-equipment-in-installations.component.less']
})
export class ImportsCsvInstallationsComponent extends Unsubscriber {
  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    private installationService: InstallationService,
    private equipmentService: EquipmentsService,
    public dialog: MatDialog
  ) {
    super();
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  /** Array que armazena os dados do arquivo CSV **/
  private dataToImport: Array<any> = [];

  private data: string;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean = false;

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Loading de update **/
  public loadingUpdate: boolean = false;

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Utilizado para manipular a msg exibida após os logs estarem disponíveis para download (sem o update) **/
  public logReadyMessage: boolean = false;

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa as linhas dos logs **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza o mapeamento do retorno das propriedade de aplicações nas instalações **/
  private applicationMapping(application: string): any {

    let response: string = '';

    switch (application) {
      case 'light':
        response = 'PUBLIC_LIGHTING';
        break;

      case 'electricity':
        response = 'ELECTRICITY_CONSUMPTION';
        break;

      case 'water':
        response = 'WATER_CONSUMPTION';
        break;

      case 'gas':
        response = 'GAS_CONSUMPTION';
        break;

      default:
        response = ''
        break;
    }
    return response;
  }

  /** Método que realiza a leitura e importação dos arquivos CSV **/
  public async importDataFromCSV() {

    /** Armazena os dados do CSV **/
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    /** Constante que armazena o objeto que contém as propriedades de referência e numero de série do arquivo **/
    const dataMappings = this.dataToImport.map(row => ({
      reference: row.reference,
      serial: row.serial,
    }));

    // /** Para cada linha do arquivo **/
    for (const data of dataMappings) {
      /** Verifica se possui o cabeçalho de "reference" e "serial" **/
      if (!data.reference || !data.serial) {
        await Swal.fire({
          title: 'Cabeçalho ou formato de arquivo incorreto',
          text: 'Por favor, verifique o arquivo e tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        /** Interrompe a execução do restante da função **/
        return;
      }
    }

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(
      /** Processa as instalações e equipamentos limitando as solicitações em 5 **/
      mergeMap(({ reference, serial }) => this.processInstallationAndUpdate(reference, serial), 5),
      /** Caso ocorra algum erro **/
      catchError(err => {
        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;
        /** Desativa o loading **/
        this.loadingUpdate = false;
        /** Botão é ativado **/
        this.disableImportButton = false;
        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {
            /** Ativa o botão de exportar logs **/
            this.isExported = true;
            /** Desativa o loading **/
            this.loadingUpdate = false;
            /** Botão de importação é desativado **/
            this.disableImportButton = true;
            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;
            /** Reseta o contador das linhas **/
            this.actualLine = 0;
            /** Limpa o input dos arquivos que contém o arquivo anexado 
           já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Definindo a função com parâmetros de referência e número de série e retorno de um Observable **/
  private processInstallationAndUpdate(reference: string, serial: string): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;
    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;
    /** Desabilita o botão de importação **/
    this.disableImportButton = true;

    /** Variável que armazena as informações das instalações  **/
    let installation: Installation;

    /** Variável que armazena as informações do equipamento*/
    let equipment: Device;

    /** Realiza o filtro de instalações **/
    return this.installationService.getInstallationByReference(reference).valueChanges.pipe(
      take(1),

      /** Mapeamento dos resultados obtidos do filtro de instalação **/
      mergeMap((res: any) => {

        /** Filtrando instalações pela referência exata obtida no arquivo **/
        const installations: Installation[] = res.data.installation.edges.map((edge: any) => edge.node)
          .filter((install: Installation) => install.reference === reference)

        /** Caso a instalação não seja encontrada **/
        if (installations.length === 0) {
          /** Adiciona na lista de logs **/
          this.log.push(`Referência ${reference} não encontrada nas instalações válidas!`);

          /** Encerrando esse processo nas referências */
          return of(null);
        }

        /** Atribuindo apenas uma instalação com a referência correta **/
        installation = installations[0];

        /** Realiza o filtro de equipamentos através do numero de série existente no arquivo **/
        return this.equipmentService.filterEquipmentBySerialNumber(serial, false).valueChanges.pipe(
          /** Limita o numero de valores do observable em 1 apenas, evitando o vazamento de memória **/
          take(1),
          /** Mapeando os resultados obtidos do filtro de equipamentos **/
          mergeMap((res: any) => {

            /** Encontrando o equipamento com o número de série exato contido no arquivo **/
            const equipments: Device[] = res.data.equipment.edges.map((edge: any) => edge.node)
              .filter((equip: Device) => equip.serialNumber === serial);

            /** Caso o equipamento não seja encontrado **/
            if (equipments.length === 0) {
              /** Adiciona na lista de logs de instalações **/
              this.log.push(`Numero de série ${serial} não encontrado nos equipamentos válidos!`);

              /** Encerrando esse processo dos numeros de série */
              return of(null);
            }

            equipment = equipments[0];

            /** Realiza a atualização da instalação com o numero de série do equipamento e a referência da instalação que foram encontrados **/
            return this.importsCSVFileService.updateInInstallation(
              installation?.id,
              installation?.reference,
              installation?.site?.id,
              equipment?.id,
              installation?.isActive,
              installation?.hasMagneticKey,
              this.applicationMapping(installation?.applications[0]),
              installation?.lampType?.id,
              installation?.gateway?.id,
              installation?.division?.id
            ).pipe(
              tap(() => {
                this.log.push(`Equipamento ${serial} vinculado na instalação ${reference} com sucesso!`);
              }),

              /** Caso ocorra algum erro na atualização **/
              catchError(err => {
                /** Exibe o erro no console **/
                console.error(`Erro ao vincular o equipamento ${serial} na instalação ${installation.reference}`, err);

                /** Adiciona o erro na lista de logs **/
                this.log.push(`Erro ao vincular o equipamento ${serial} na instalação ${installation.reference} (${err})`);

                /** Continua o processo mesmo com erro **/
                return of(null);
              })
            );
          })
        );
      }),
      /** Caso ocorra algum erro nas instalações **/
      catchError(err => {
        /** Exibe o erro no console **/
        console.log('Erro na obtenção ou atualização de instalações', err);

        /** Adiciona o erro na lista de logs **/
        this.log.push(`Erro na obtenção ou atualização de instalações: ${installation.reference} (${err})`);

        /** Continua o processo mesmo com erro **/
        return of(null);
      })
    );
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Update_Equipment_in_installation.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método utilizado para gerar o conteúdo do CSV a partir dos cabeçalhos **/
  private generateCsvContent(headers: string[]): string {
    return headers.join(';') + '\n'; // Agrupa os cabeçalhos separando por ponto e vírgula e adiciona uma nova linha no final
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  public downloadCsvFile(): void {
    const headers = ['reference', 'serial']; //Define o cabeçalho do arquivo csv
    const fileName = 'model-import-link-equipment-on-installation.csv'; //Nome do arquivo
    const csvContent = this.generateCsvContent(headers); // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
