import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InstallationLogService } from 'src/shared/services/installation-log.service';
import { jsPDF } from 'jspdf';
import { Installation, InstallationLog, PaginationInfo } from './installation-log-assets/installation-log-model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import Swal from 'sweetalert2';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { MapjsService } from 'src/shared/services/mapjs.service';
import { MatDialog } from '@angular/material/dialog';
import { tzConvertISOEndDate, tzConvertISOStartDate, tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-installation-log',
  templateUrl: './installation-log.component.html',
  styleUrls: ['./installation-log.component.less']
})

export class InstallationLogComponent extends Unsubscriber implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private installationLogService: InstallationLogService,
    private errorLibService: ErrorLibService,
    private mapjsService: MapjsService,
    private dialog: MatDialog

  ) {
    super();
  }

  ngOnInit(): void {
    /** Aciona o observable que armazena o estado da propriedade que redireciona do compoenente do mapa p/ o componente de logs **/
    this.mapjsService.logsSubject$.subscribe((value) => {
      /** Caso o estado da propriedade seja true **/
      if (value) {
        /** Armazena o id da instalação que foi armazenado no localstorage do navegador **/
        this.installationID = localStorage.getItem('installationId') ? JSON.parse(localStorage.getItem('installationId')!) : null;
        this.filterInstallationLogs(null)
      }
    }).unsubscribe();
  }

  /** Método que é executado sempre que o componente é encerrado **/
  public override ngOnDestroy(): void {
    /** Caso ocorra uma subscrição **/
    if (this.subscriptions) {
      /** Cancelar todas as susbcrições da tela de logs **/
      this.subscriptions.unsubscribe();
    }
    /** Desativa a exibição dos logs **/
    this.mapjsService.setLogsFalse();
  }
  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  // Variaveis responáveis por abrir e fechar as boxes
  public filterBox: boolean = false;
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  // Variaveis responsáveis pelos loadings da tela
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;
  public filterInstallationLoading: boolean = false;

  /** Propriedade que verifica quando o input de referência possui algum conteúdo (Utilizado para exibir o alerta para o usuário)**/
  public inputReferenceController: boolean = false;

  /**  Propriedade que controla as referências das instalações selecionadas ou não **/
  public selectedInstallationController: boolean = false;

  // Função responsável por mudar o estado da box de filtro
  public filterBoxOpen(): void {
    this.filterBox = !this.filterBox;
  }

  /** Variáveis utilizadas para armazenar o filtro do usuário **/
  public operation: string;
  public startDatetime: any;
  public endDatetime: any;
  public reference: string;
  public username: string;
  public installationID: any; // Armazena o id da instalação

  /** Propriedade utilizada para habilitar a informação de equipamentos atualizados **/
  public updatedEquipment: boolean;

  /** Propriedade utilizada para habilitar a informação de equipamentos removidos **/
  public removedEquipment: boolean;

  /** Propriedade utilizada para habilitar a informação de equipamentos adicionados **/
  public addEquipment: boolean;

  /** Propriedade utilizada para habilitar a informação de instalações atualizadas **/
  public updatedInstallationReference: boolean;

  /** Propriedade que armazena o numero de série do equipamento anterior **/
  public previousEquipmentSerialNumber: string;

  /** Propriedade que armazena o numero de série do equipamento atual **/
  public equipmentSerialNumber: string;

  /** Propriedade que armazena a referência da instalação atual **/
  public referenceInstallation: string;

  /** Propriedade que armazena a referência da instalação anterior **/
  public previousReferenceInstallation: string;

  /** Propriedade que armazena o status da instalação **/
  public isInactivatedInstallation: boolean;

  /** Propriedade que armazena o contador de informações de operações **/
  public updatedInfoCount: number = 0;

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: InstallationLog[] = [];
  public installationList: Installation[] = [];

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] =
    [
      "username",
      "operation",
      "logDatetime",
      "equipmentSerialNumber",
      "previousEquipmentSerialNumber",
      "reference",
      "siteReference",
      "gatewayReference",
      "isActive",
      "lampModel",
      "divisionReference"
    ];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<InstallationLog>(this.ELEMENT_DATA);

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /**
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     *
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterInstallationLogs(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterInstallationLogs(this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public FILE_DATA: InstallationLog[] = [];
  public isReady: boolean = false;

  /**
   * Função que configura os dados da tabela.
   */
  setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Armazena os dados do formulário de filtro de logs, o valor é setado automáticamente pelo HTML
  public filterLogsForm: FormGroup = this.formBuilder.group({
    operation: null,
    startDateForm: null,
    endDateForm: null,
    reference: null,
    username: null,
  });

  /**
   * Função responsavel por filtrar logs
   */
  public filterInstallationLogs(cursor: string | null) {

    this.operation = this.filterLogsForm.get('operation')?.value || null;

    this.startDatetime = this.filterLogsForm.get('startDateForm')?.value
      ? tzConvertISOStartDate(this.filterLogsForm.get('startDateForm')?.value)
      : null;

    this.endDatetime = this.filterLogsForm.get('endDateForm')?.value
      ? tzConvertISOEndDate(this.filterLogsForm.get('endDateForm')?.value)
      : null;

    this.reference = this.filterLogsForm.get('reference')?.value || null;

    this.username = this.filterLogsForm.get('username')?.value || null;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.filterLoading = true;

    this.subscriptions = this.installationLogService.filterLogs(
      this.operation,
      this.startDatetime,
      this.endDatetime,
      this.username,
      this.installationID,
      cursor,
      this.pageSize,
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data?.installationLog?.count,
          response.data?.installationLog?.total,
          response.data?.installationLog?.pageInfo?.hasNextPage,
          response.data?.installationLog?.pageInfo?.hasPreviousPage,
          response.data?.installationLog?.pageInfo?.startCursor,
          response.data?.installationLog?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        this.isReady = false;
        this.length = this.paginationProperties.total;

        // Adiciona cada log retornado pela requisição na lista
        response.data.installationLog.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(new InstallationLog(
            node?.node.id,
            node?.node?.username,
            node?.node?.operation,
            node?.node?.logDatetime,
            node?.node?.equipmentSerialNumber,
            node?.node?.previousEquipmentSerialNumber,
            node?.node?.reference,
            node?.node?.siteReference,
            node?.node?.gatewayReference,
            node?.node?.isActive,
            node?.node?.lampModel,
            node?.node?.divisionReference,
            node?.node?.installationId
          ));
        });

        this.setDataSourceAttributes();

        /** Desativa o loading **/
        this.filterLoading = false;

        /** Limpa o campo de id já filtrado **/
        this.installationID = null;

        /** Limpa o select com a lista de instalações **/
        this.installationList = []

        /** Limpa o campo de referência **/
        this.filterLogsForm.get('reference')?.reset();

        /** Habilita a edição do campo de referência **/
        this.filterLogsForm.get('reference')?.enable();

        /** Reseta os valores das propriedades que manipulam o campo de referencia e seleção de instalações **/
        this.inputReferenceController = false;
        this.selectedInstallationController = false;

        /** Caso a pesquisa não encontre resultados **/
        if (this.ELEMENT_DATA.length === 0) {
          // Retorna um aviso de erro ao usuário
          Swal.fire({
            title: 'Não Encontrado',
            text: 'Não há registros encontrados para esta busca.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
      }),
      /** Caso ocorra algum erro na requisição **/
      error: ((error: any) => {
        /** Exibe erro no console **/
        console.log("InstallationLogQuery", error);
        /** Exibe um alerta para o usuário **/
        this.errorLibService.errorAlert(error);
        /** Desativa o loading **/
        this.filterLoading = false;
      })
    });
  }

  /** Realiza o filtro de instalações a partir de uma referência informada pelo usuário no input **/
  public searchInstallationLogsByReference(reference: any) {

    /** Limpa a lista de instalações do select **/
    this.installationList = [];

    /** Ativa o loading na tela **/
    this.filterInstallationLoading = true;

    /** Realiza o filtro de instalações a partir da instalação informada pelo usuário **/
    this.installationLogService.getInstallationByreference(reference).valueChanges
      .subscribe({
        next: ((res: any) => {
          res.data.installation.edges.forEach((node: any) => {
            this.installationList.push(
              new Installation(
                node.node.id,
                node.node.reference
              )
            )
          });

          /** Desativa o loading na tela **/
          this.filterInstallationLoading = false;

          /** Campo de referência foi filtrado **/
          this.inputReferenceController = true;

          if (this.installationList.length === 0) {
            // Retorna um aviso de erro ao usuário
            Swal.fire({
              title: 'Instalação não encontrada',
              text: 'Não há registros encontrados para esta busca.',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          }
        }),
        /** Caso ocorra algum erro na requisição **/
        error: ((error: any) => {
          /** Exibe erro no console **/
          console.log("filterInstallationsQuery", error);
          /** Exibe alerta para o usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading na tela **/
          this.filterInstallationLoading = false;
          /** Reseta o estado da propriedade que manipula o input de referência **/
          this.inputReferenceController = false;
        })
      });
  }

  /** Método utilizado para que quando o usuário selecione uma opção das referências
   * o valor do input digitado também ser atualizado 
   * (utilizado para garantir que a referência a ser filtrada seja uma referência válida, já que o filtro de logs não é do tipo 'contains'.)
   * **/
  public onSelectInstallation(event: any) {

    /** Armazena o id da instalação que o usuário selecionou **/
    this.installationID = event.value;

    /** Utilizado para buscar a referência da instalação a partir do id que foi selecionado 
     * (a referência será utilizada para substituir o campo de referência acima e bloquear a alteração do mesmo **/
    const selectedInstallation = this.installationList.find(installation => installation.id === event.value);

    /** Caso alguma instalação seja selecionada **/
    if (event.value) {
      /** Atualiza o valor do input de acordo com a referência da instalação selecionada **/
      this.filterLogsForm.get('reference')?.setValue(selectedInstallation?.reference);
      /** Desabilita a alteração do input **/
      this.filterLogsForm.get('reference')?.disable();
      /** Altera o estado da propriedade que manipula as instalações selecionadas, ocultando o alerta na tela **/
      this.selectedInstallationController = true;
      /** Altera o estado do input de referência, ocultando o alerta na tela**/
      this.inputReferenceController = true;
    }
  }

  // Método para obter a chave de tradução correta com base em 'element.operation'
  public getTranslationKey(element: any): string {
    return `logs.form-${element.operation}-label`;
  }

  // Método para obter a chave de tradução correta com base em 'element.isActive'
  public getTranslationKeyIsActive(element: any): string {
    return `logs.form-${element.isActive}-label`;
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.installationLogService.filterLogs(
        this.operation,
        this.startDatetime,
        this.endDatetime,
        this.username,
        null,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.installationLog.edges.forEach((node: any) => {

            /** Tratamento de dados retornados da API **/
            this.FILE_DATA.push(
              new InstallationLog(
                node?.node.id,
                node?.node?.username,
                node?.node?.operation,
                node?.node?.logDatetime,
                node?.node?.equipmentSerialNumber,
                node?.node?.previousEquipmentSerialNumber,
                node?.node?.reference,
                node?.node?.siteReference,
                node?.node?.gatewayReference,
                node?.node?.isActive,
                node?.node?.lampModel,
                node?.node?.divisionReference,
                node?.node?.installationId
              )
            );
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.installationLog?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.installationLog?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).toString();
      })
      .join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((log) => {
      content.push({
        'Usuário': log.username,
        'Operação': log.operation,
        'Data': tzConvertUTC2Local(log.logDatetime),
        'Número de Série Atual': log.equipmentSerialNumber,
        'Número de Série Anterior': log.previousEquipmentSerialNumber,
        'Referência': log.reference,
        'Referência do Local': log.siteReference,
        'Concentrador': log.gatewayReference,
        'Ativo': this.translateStatus(log.isActive),
        'Modelo da Lâmpada': log.lampModel,
        'Divisão': log.divisionReference,
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-installation-log.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do CSV.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = [
      "Usuário",
      "Operação",
      "Data",
      "Número de Série Atual",
      "Número de Série Anterior",
      "Referência",
      "Referência do Local",
      "Concentrador",
      "Ativo",
      "Modelo da Lâmpada",
      "Divisão"
    ];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((log) => {
      content.push([
        log.username,
        log.operation,
        tzConvertUTC2Local(log.logDatetime),
        log.equipmentSerialNumber,
        log.previousEquipmentSerialNumber,
        log.reference,
        log.siteReference,
        log.gatewayReference,
        this.translateStatus(log.isActive),
        log.lampModel,
        log.divisionReference,
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-installation-log.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Função criada para tratar os dados de status para os relatórios CSV e PDF **/
  public translateStatus(status: any): string {
    switch (status) {
      /** Caso o status seja ativado **/
      case true:
        /** Retorna o status da instalação como ativa */
        return "Ativa";

      /** Caso o status seja inativado **/
      case false:
        /**Retorna o status da instalação como inativa */
        return "Inativa";
    }
    /** Retorna o status para utilizar nos arquivos que geram os arquivos de PDF e CSV **/
    return status;
  }

  /** Método utilizado para abrir o modal que exibe as informações relacionado as alterações dos logs **/
  public openModalInfoOperations(element: any) {

    /** Abre o modal de informações das operações **/
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });

    /** Reseta o contador e todas as propriedades que manipulam as informações referente as alterações realizadas nos logs **/
    this.updatedInfoCount = 0;
    this.updatedEquipment = false;
    this.removedEquipment = false;
    this.addEquipment = false;
    this.updatedInstallationReference = false;
    this.isInactivatedInstallation = false;
    this.previousReferenceInstallation = '';

    /** Armazena a referência da instalação selecionada **/
    this.referenceInstallation = element?.reference;

    /** Armazena o número de série do equipamento anterior referente ao log que foi selecionado **/
    this.previousEquipmentSerialNumber = element?.previousEquipmentSerialNumber;

    /** Armazena o número de série do equipamento atual referente ao log que foi selecionado **/
    this.equipmentSerialNumber = element?.equipmentSerialNumber;

    /** Constante que armazena o id do log **/
    const logId: string = element?.id;

    /** Constante que armazena o status da instalação **/
    const isActive: any = element.isActive;

    /** Constante que armazena o id da instalação **/
    const installationId: string = element?.installationId;

    /** Constante que armazena o index da instalação referente ao log selecionado, filtrando a partir do id do log  **/
    const indexInstallation = this.ELEMENT_DATA.findIndex(installationLogs => installationLogs?.id === logId);

    /** Constante que armazana o próximo index da instalação selecionada **/
    const nextIndexInstallation: string | any = this.ELEMENT_DATA[indexInstallation + 1]

    /** Armazena a referência do próximo index da instalação selecionada **/
    this.previousReferenceInstallation = nextIndexInstallation?.reference;

    /** Verifica se o numero de série do equipamento anterior é diferente do número de série atual
     * e se o numero de série do equipamento anterior e o atual não são nulos.
     *  **/
    if (this.previousEquipmentSerialNumber !== this.equipmentSerialNumber
      && this.previousEquipmentSerialNumber !== null && this.equipmentSerialNumber !== null) {
      /** Altera o estado da propriedade que habilita a informação sobre a atualização de equipamentos **/
      this.updatedEquipment = true;

      /** Acrescenta +1 ao contador **/
      this.updatedInfoCount += 1;
    }

    /** Verifica se o numero de série anterior do equipamento é nulo e se o numero de série atual não é nulo **/
    if (this.previousEquipmentSerialNumber === null && this.equipmentSerialNumber !== null) {
      /** Altera o estado da propriedade que habilita a informação sobre a adição de equipamentos **/
      this.addEquipment = true;

      /** Acrescenta +1 ao contador **/
      this.updatedInfoCount += 1;
    }

    /** Verifica se o numero de série anterior do equipamento  e o numero de série atual são diferentes de nulo **/
    if (this.previousEquipmentSerialNumber !== null && this.equipmentSerialNumber === null) {
      /** Altera o estado da propriedade que habilita a informação sobre a remoção de equipamentos **/
      this.removedEquipment = true;

      /** Acrescenta +1 ao contador **/
      this.updatedInfoCount += 1;
    }

    /** Verifica se o id da instalação é igual ao id do index encontrado na lista de logs **/
    if (installationId === nextIndexInstallation?.installationId) {

      /** Verifica se a referência da instalação é diferente da instalação anterior (do index anterior)
       *  e se a referência não é nula **/
      if (this.referenceInstallation !== this.previousReferenceInstallation
        && this.referenceInstallation !== null) {

        /** Altera o estado da propriedade que habilita a informação sobre a atualização da referência **/
        this.updatedInstallationReference = true;

        /** Acrescenta +1 ao contador **/
        this.updatedInfoCount += 1;
      }
    }

    /** Verifica se o numero de série atual está nulo e o status da instalação é falso (inativo) **/
    if (this.equipmentSerialNumber == null && isActive === false) {
      /** Altera o estado da propriedade que exibe a mensagem no log **/
      this.isInactivatedInstallation = true;
      /** Acrescenta +1 ao contador **/
      this.updatedInfoCount += 1;
    }
  }

  /** Realiza a conversão da data p/ fuso horário do navegador ou fuso horário especificado pelo usuário 
 * (Utilizado na data exibida na tabela de logs) **/
  public formatDate(date: string): any {
    return tzConvertUTC2Local(date)
  }
}
